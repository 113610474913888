import React, { Component } from 'react';
class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Our main focus is creating beautiful and functional outdoor spaces that enhance the value and enjoyment of your property. From landscape design and installation to lawn care and maintenance, we take pride in delivering high-quality services that meet the unique needs and preferences of each of our clients.',
                    text02: ''
                },
                {
                    id: 2,
                    text01: 'We are dedicated to delivering exceptional landscaping services that exceed our clients expectations. From the initial consultation to the final installation, we are committed to providing personalized service and attention to detail on every project we undertake.',
                    text02: ''
                },
                {
                    id: 3,
                    text01: 'We believe that every outdoor space deserves to be beautiful, functional, and sustainable, and we work tirelessly to make that a reality for our clients. Our team of experienced professionals uses the latest techniques and technology to create custom landscapes that are tailored to each clients unique needs and preferences',
                    text02: ''
                }
            ]
        };
    }

    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div
                        className="themesflat-spacer clearfix"
                        data-desktop={80}
                        data-mobile={60}
                        data-smobile={60}
                    />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_1_of_6 bg-f7f max-height-450">
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={60}
                                data-mobile={60}
                                data-smobile={60}
                            />
                            <div
                                className="themesflat-content-box clearfix"
                                data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    <div className="sup-heading">SUMMER SUN LANDSCAPE</div>
                                    <h2 className="heading font-size-28 line-height-39">
                                        LANDSCAPING SERVICES
                                    </h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">
                                        Summer Sun Landscape is committed to excellence in every
                                        aspect of our business. We uphold a standard of integrity
                                        bound by fairness, honesty, and personal responsibility. Our
                                        distinction is the quality of service we bring to our
                                        customers. Accurate knowledge of our trade combined with
                                        ability is what makes us true professionals. Above all, we
                                        are watchful of our customers interests and make their
                                        concerns the basis of our business. Please give us a call
                                        today!
                                    </p>
                                </div>
                            </div>
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={56}
                                data-mobile={56}
                                data-smobile={56}
                            />
                        </div>
                        <div className="span_1_of_6 half-background style-2"></div>
                    </div>
                    <div
                        className="themesflat-spacer clearfix"
                        data-desktop={39}
                        data-mobile={39}
                        data-smobile={39}
                    />

                    <div
                        className="themesflat-spacer clearfix"
                        data-desktop={37}
                        data-mobile={35}
                        data-smobile={35}
                    />
                    <div className="flat-content-wrap style-2 clearfix">
                        <div className="item">
                            <div className="inner">
                                <div
                                    className="themesflat-spacer clearfix"
                                    data-desktop={10}
                                    data-mobile={10}
                                    data-smobile={10}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flat-content-wrap style-2 clearfix">
                        <div
                            className="themesflat-spacer clearfix"
                            data-desktop={27}
                            data-mobile={27}
                            data-smobile={27}
                        />
                        <div className="themesflat-tabs style-2 title-w170 clearfix">
                            <ul className="tab-title clearfix ">
                                <li className="item-title active">
                                    <span className="inner">Our focus</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Dedicated</span>
                                </li>
                                <li className="item-title">
                                    <span className="inner">Committed</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {this.state.tabs.map((data) => (
                                    <div className="tab-content" key={data.id}>
                                        <div className="item-content">
                                            <p>{data.text01}</p>
                                            <p>{data.text02}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className="themesflat-spacer clearfix"
                        data-desktop={80}
                        data-mobile={60}
                        data-smobile={60}
                    />
                </div>
            </div>
        );
    }
}

export default SiteContent;
