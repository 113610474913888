import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './components/pages/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter>
            <ToastContainer style={{ zIndex: 10000 }} position="top-right" />
            <Switch>
                {routes.map((data, idx) => (
                    <Route exact path={data.path} component={data.component} key={idx}></Route>
                ))}
            </Switch>
        </BrowserRouter>
    );
}

export default App;
