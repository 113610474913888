import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Sidebar extends Component {
    render() {
        return (
            <div id="sidebar">
                <div
                    className="themesflat-spacer clearfix"
                    data-desktop={80}
                    data-mobile={0}
                    data-smobile={0}
                />
                <div id="inner-sidebar" className="inner-content-wrap">
                    <div className="widget widget_list">
                        <div className="inner">
                            <ul className="list-wrap">
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Landscape Design</span>
                                    </Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Lawn Care </span>
                                    </Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Tree and Shrub Care</span>
                                    </Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Irrigation</span>
                                    </Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Maintenance Services</span>
                                    </Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#">
                                        <span className="text">Garden Design</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget widget_help align-center has-shadow no-sep has-border border-solid">
                        <div className="inner">
                            <h2 className="widget-title margin-bottom-14">
                                <span>HOW CAN WE HELP ?</span>
                            </h2>
                            <p className="text line-height-26 margin-bottom-23">
                                Transform your outdoor space with our expert landscaping services.
                                Contact us today for a free consultation and quote!
                            </p>
                            <div className="elm-button">
                                <a href="contacts" className="themesflat-button bg-accent pd30">
                                    GET IN TOUCH
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="themesflat-spacer clearfix"
                    data-desktop={0}
                    data-mobile={60}
                    data-smobile={60}
                />
            </div>
        );
    }
}

export default Sidebar;
