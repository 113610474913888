const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isValidPhoneNumber = (phone) => {
    const phoneNumberRegex = /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;
    return phoneNumberRegex.test(phone);
};

export const isValidForm = (formData) => {
    const { name, email, phone, message } = formData;

    if (name.trim() === '' || message.trim() === '') {
        return false;
    }

    if (!isValidEmail(email)) {
        return false;
    }

    if (phone.trim() !== '' && !isValidPhoneNumber(phone)) {
        return false;
    }

    return true;
};
