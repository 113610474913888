import React, { useState } from 'react';
import { TitleHeading } from '../../layouts/home01/index';
import { isValidForm } from '../../../utils/validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContact02 = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const headingservice = [
        {
            id: 1,
            classnames: 'heading',
            title: 'Send Us A Message',
            classtext: 'sub-heading'
        }
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isValidForm(formData)) {
            console.error('Invalid form data');
            toast.error('Please fill out all required fields');
            return;
        }
        try {
            console.log('Submitting form...');
            console.log(formData);
            console.log(process.env.REACT_APP_FUNCTIONS_URL);
            const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/submit_form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(formData).toString()
            });

            const result = await response.json();
            if (result.success) {
                toast.success('Thank you for your message!');
                console.log('Form submitted successfully');
                // Clear the form data
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                // You can show a success message or perform any other actions you want
            } else {
                console.error('Form submission failed');
                toast.error('An error occurred. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="row-contact">
            <div className="container">
                {headingservice.map((data) => (
                    <TitleHeading data={data} key={data.id} />
                ))}
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="themesflat-contact-form style-2 w100 clearfix">
                            <form onSubmit={handleSubmit} className="contact-form wpcf7-form">
                                <span className="wpcf7-form-control-wrap your-name">
                                    <input
                                        type="text"
                                        tabIndex={1}
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="wpcf7-form-control"
                                        placeholder="Name*"
                                        required
                                    />
                                </span>
                                <span className="wpcf7-form-control-wrap your-email">
                                    <input
                                        type="email"
                                        tabIndex={3}
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="wpcf7-form-control"
                                        placeholder="Your Email*"
                                        required
                                    />
                                </span>
                                <span className="wpcf7-form-control-wrap your-phone">
                                    <input
                                        type="text"
                                        tabIndex={2}
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="wpcf7-form-control"
                                        placeholder="Phone"
                                    />
                                </span>
                                <span className="wpcf7-form-control-wrap your-message">
                                    <textarea
                                        name="message"
                                        tabIndex={5}
                                        cols={40}
                                        rows={10}
                                        className="wpcf7-form-control wpcf7-textarea"
                                        placeholder="Message*"
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </span>
                                <span className="wrap-submit d-flex justify-content-center">
                                    <input
                                        type="submit"
                                        defaultValue="SEND US"
                                        className="submit wpcf7-form-control wpcf7-submit"
                                        id="submit"
                                        name="submit"
                                    />
                                </span>
                                <div className="wpcf7-form-message"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormContact02;
