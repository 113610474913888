import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    id: 1,
                    srcimg: 'assets/img/project/land-3.jpg',
                    heading: 'Fencing',
                    meta: 'Fencing',
                    text: ''
                },
                {
                    id: 2,
                    srcimg: 'assets/img/project/land-2.jpg',
                    heading: 'Fencing',
                    meta: 'Fencing',
                    text: ''
                },
                {
                    id: 3,
                    srcimg: 'assets/img/project/stone-2.jpg',
                    heading: 'Paving',
                    meta: 'Paving',
                    text: ''
                },
                {
                    id: 4,
                    srcimg: 'assets/img/project/land-1.jpg',
                    heading: '',
                    meta: 'Planting',
                    text: ''
                }
            ]
        };
    }
    render() {
        return (
            <div
                className="themesflat-carousel-box clearfix"
                data-gap={30}
                data-column={4}
                data-column2={2}
                data-column3={1}
                data-auto="false">
                <div className="owl-carousel owl-theme">
                    {this.state.data.map((data) => (
                        <div
                            className="themesflat-project style-1 data-effect  clearfix"
                            key={data.id}>
                            <div className="project-item">
                                <div className="inner">
                                    <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                                        <img
                                            data-aos="fade-up"
                                            data-aos-anchor-placement="bottom-bottom"
                                            src={data.srcimg}
                                            alt="altimage"
                                        />
                                        <div className="text-wrap text-center">
                                            <h5 className="heading">
                                                <Link to="#">{data.heading}</Link>
                                            </h5>
                                            <div className="elm-meta">
                                                <span>
                                                    <Link to="#">{data.meta}</Link>
                                                </span>
                                                <span>
                                                    <Link to="#">{data.text}</Link>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="elm-link">
                                            <Link
                                                to="services"
                                                className="icon-1 icon-search"></Link>
                                            <Link to="services" className="icon-1"></Link>
                                        </div>
                                        <div className="overlay-effect bg-color-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Project;
