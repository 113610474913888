import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

class SliderService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slider: [
                {
                    id: 2,
                    srcimg: 'assets/img/imagebox/paver.jpg',
                    heading: 'Stone, Deck & Paving',
                    text: 'We work closely with architects to understand the project, and ultimately develop a targeted...'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/imagebox/grass.jpg',
                    heading: 'Mowing & Mulching',
                    text: 'In the past decade alone, we have completed more than 5 million square feet of metal building...'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/imagebox/mulch.jpg',
                    heading: 'Landscape Design',
                    text: 'We develop and understand project expectations and then manage those needs with a design team...'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/imagebox/fencing.jpg',
                    heading: 'Fencing',
                    text: 'We work closely with architects to understand the project, and ultimately develop a targeted...'
                }
            ]
        };
    }
    componentDidMount() {
        AOS.init();
    }
    render() {
        return (
            <div
                className="themesflat-carousel-box has-arrows arrow-center offset-v-111 offset-h-21 arrow-circle arrow-style-2 data-effect clearfix"
                data-gap={30}
                data-column={3}
                data-column2={2}
                data-column3={1}
                data-auto="true">
                <div className="owl-carousel owl-theme">
                    {this.state.slider.map((data) => (
                        <div className="themesflat-image-box style-2 clearfix" key={data.id}>
                            <div className="image-box-item">
                                <div className="inner">
                                    <div className="thumb data-effect-item">
                                        <img
                                            data-aos-offset="200"
                                            data-aos-easing="ease-in-sine"
                                            data-aos-duration="600"
                                            data-aos="zoom-in"
                                            src={data.srcimg}
                                            alt="altimage"
                                        />
                                        <div className="overlay-effect bg-color-accent" />
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading">
                                            <Link to="#">{data.heading}</Link>
                                        </h5>
                                        {/* <p>{data.text}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default SliderService;
