import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,
                    classnames: 'project-item fencing',
                    srcimg: 'assets/img/project/project-1.jpg',
                    name01: 'Fencing',
                    name02: ''
                },
                {
                    id: 12,
                    classnames: 'project-item fencing',
                    srcimg: 'assets/img/project/fencing-1.jpg',
                    name01: 'Fencing',
                    name02: ''
                },
                {
                    id: 13,
                    classnames: 'project-item fencing',
                    srcimg: 'assets/img/project/fencing-2.jpg',
                    name01: 'Fencing',
                    name02: ''
                },
                {
                    id: 2,
                    classnames: 'project-item paving',
                    srcimg: 'assets/img/project/paving-2.jpg',
                    heading: '',
                    name01: 'Paving'
                },
                {
                    id: 3,
                    classnames: 'project-item paving',
                    srcimg: 'assets/img/project/paving-3.jpg',
                    heading: '',
                    name01: 'Paving'
                },
                {
                    id: 4,
                    classnames: 'project-item paving',
                    srcimg: 'assets/img/project/paving-4.jpg',
                    heading: '',
                    name01: 'Paving'
                },
                {
                    id: 5,
                    classnames: 'project-item  lawn',
                    srcimg: 'assets/img/project/project-5.jpg',
                    heading: '',
                    name01: 'Lawn',
                    name02: ''
                },
                {
                    id: 6,
                    classnames: 'project-item lawn',
                    srcimg: 'assets/img/project/grass.jpg',
                    heading: '',
                    name01: 'Lawn'
                },
                {
                    id: 16,
                    classnames: 'project-item lawn',
                    srcimg: 'assets/img/project/grass-2.jpg',
                    heading: '',
                    name01: 'Lawn'
                },
                {
                    id: 17,
                    classnames: 'project-item stone',
                    srcimg: 'assets/img/project/stone-2.jpg',
                    heading: '',
                    name01: 'Stone'
                },
                {
                    id: 18,
                    classnames: 'project-item stone',
                    srcimg: 'assets/img/project/stone-3.jpg',
                    heading: '',
                    name01: 'Stone'
                },
                {
                    id: 19,
                    classnames: 'project-item stone',
                    srcimg: 'assets/img/project/stone-1.jpg',
                    heading: '',
                    name01: 'Stone'
                },
                {
                    id: 20,
                    classnames: 'project-item land',
                    srcimg: 'assets/img/project/stone-2.jpg',
                    heading: '',
                    name01: 'Landscaping'
                },
                {
                    id: 21,
                    classnames: 'project-item land',
                    srcimg: 'assets/img/project/land-3.jpg',
                    heading: '',
                    name01: 'Landscaping'
                },
                {
                    id: 22,
                    classnames: 'project-item land',
                    srcimg: 'assets/img/project/land-1.jpg',
                    heading: '',
                    name01: 'Landscaping'
                }
            ]
        };
    }

    render() {
        return (
            <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
                {this.state.projects.map((data) => (
                    <div className={data.classnames} key={data.id}>
                        <div className="inner">
                            <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                                <img src={data.srcimg} alt="altimage" />
                                <div className="elm-link">
                                    <Link to="#" className="icon-1 icon-search" />
                                    <Link to="#" className="icon-1" />
                                </div>
                                <div className="overlay-effect bg-color-3" />
                            </div>
                            <div className="text-wrap">
                                <h5 className="heading">
                                    <Link to="#">{data.heading}</Link>
                                </h5>
                                <div className="elm-meta">
                                    <span>
                                        <Link to="#">{data.name01}</Link>
                                    </span>
                                    <span>
                                        <Link to="#"></Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default TabProject;
