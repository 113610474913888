import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="clearfix">
                <div id="footer-widgets" className="container">
                    <div className="themesflat-row gutter-30">
                        <div className="col span_1_of_3">
                            <div className="widget widget_text">
                                <div className="textwidget">
                                    <p>
                                        <img
                                            src="assets/img/logo-new.png"
                                            alt="imagealt"
                                            width={170}
                                            height={34}
                                        />
                                    </p>
                                    <p className="margin-bottom-15 text-black">
                                        Let us bring your landscaping dreams to life.
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-map-marker" />
                                                <span className="text">South Bay, CA</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-phone" />
                                                <span className="text">CALL US : 669-233-6026</span>
                                            </div>
                                        </li>
                                        <li className="margin-top-7">
                                            <div className="inner">
                                                <span className=" font-size-14 fa fa-envelope" />
                                                <span className="text">summersun650@gmail.com</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* /.widget_text */}
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={0}
                                data-mobile={0}
                                data-smobile={35}
                            />
                        </div>
                        {/* /.col */}
                        <div className="col span_1_of_3">
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={0}
                                data-mobile={0}
                                data-smobile={0}
                            />

                            {/* /.widget_lastest */}
                        </div>
                        {/* /.col */}
                        <div className="col span_1_of_3">
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={0}
                                data-mobile={35}
                                data-smobile={35}
                            />
                            <div className="widget widget_tags">
                                <h2 className="widget-title margin-bottom-30">
                                    <span>SERVICES</span>
                                </h2>
                                <div className="tags-list">
                                    <Link to="#">Landscape Design</Link>
                                    <Link to="#">Lawn Care</Link>
                                    <Link to="#">Planting</Link>
                                    <Link to="#">Villa</Link>
                                    <Link to="#">Tree Care</Link>
                                    <Link to="#">Lawn Renovation</Link>
                                    <Link to="#">Pest and Weed Control</Link>
                                    <Link to="#">Hardscaping</Link>
                                </div>
                            </div>
                        </div>
                        {/* /.col */}
                        <div className="col span_1_of_3">
                            <div
                                className="themesflat-spacer clearfix"
                                data-desktop={0}
                                data-mobile={35}
                                data-smobile={35}
                            />
                            <div className="widget widget_instagram">
                                <h2 className="widget-title margin-bottom-30">
                                    <span>LATEST PROJECTS</span>
                                </h2>
                                <div className="instagram-wrap data-effect clearfix col3 g10">
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/1_edited.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/fencing.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/grass.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/h.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/mulch.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="instagram_badge_image has-effect-icon">
                                        <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item">
                                                <img
                                                    src="assets/img/instagram/paver.jpg"
                                                    alt="imagealt"
                                                />
                                            </span>
                                            <div className="overlay-effect bg-color-2" />
                                            <div className="elm-link">
                                                <span className="icon-3" />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
