import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import menus from '../menu';

class Header extends Component {
    render() {
        const { location } = this.props;
        return (
            <header id="site-header">
                <div id="site-header-inner" className="container">
                    <div className="wrap-inner clearfix">
                        <div id="site-logo" className="clearfix">
                            <div id="site-log-inner row ">
                                <Link
                                    to="/"
                                    onClick={() => {
                                        window.location.href = '/';
                                    }}
                                    rel="home"
                                    className="main-logo col-6">
                                    <img
                                        src={this.props.data.logoweb}
                                        alt="SummerSun"
                                        width={75}
                                        height={39}
                                        // data-retina="assets/img/logos.jpg"
                                        data-width={186}
                                        data-height={39}
                                    />
                                </Link>
                                <h6 className="menu text-white header col 6">
                                    Professional Reliable Landscapers
                                </h6>
                            </div>
                        </div>
                        <div className="mobile-button">
                            <span />
                        </div>
                        <nav id="main-nav" className="main-nav">
                            <ul id="menu-primary-menu" className="menu">
                                {menus.map((data) => (
                                    <li
                                        className={
                                            data.name === this.props.data.names
                                                ? 'menu-item menu-item-has-children current-menu-item'
                                                : 'menu-item menu-item-has-children'
                                        }
                                        key={data.id}>
                                        <a className="nav-link text-white" href={data.links}>
                                            {data.name}
                                        </a>

                                        {data.namesub !== undefined ? (
                                            <ul className="sub-menu">
                                                {data.namesub.map((submenu) => (
                                                    <li
                                                        className={
                                                            location.pathname === submenu.links
                                                                ? 'menu-item current-item'
                                                                : 'menu-item'
                                                        }
                                                        key={submenu.id}>
                                                        <Link
                                                            to={submenu.links}
                                                            onClick={() => {
                                                                window.location.href =
                                                                    submenu.links;
                                                            }}>
                                                            {submenu.sub}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
