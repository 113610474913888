import React, { Component } from 'react';

class TopBar extends Component {
    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap">
                        <div className="top-bar-content">
                            <div className="inner">
                                <span className="address content">South Bay, CA</span>
                                <span className="phone content">669-233-6026</span>
                                <span className="phone content">650-280-0467</span>
                                <span className="time content">Mon-Sat: 8am - 6pm</span>
                            </div>
                        </div>
                        <div className="top-bar-socials">
                            <div className="inner">
                                <span className="text">Follow us:</span>
                                <span className="icons">
                                    <a href="https://m.facebook.com/people/Summer-sun-landscape/100068469742756/">
                                        <i className="fa fa-facebook" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;
