import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Slider, TitleHeading } from '../layouts/home01/index';
import { IconBox, SliderService, Project } from '../layouts/home02/index';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';

class Home02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: 'assets/img/logo-new.png',
                    names: 'Home'
                }
            ],
            ourservice: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'OUR SERVICES'
                }
            ],
            leading: [
                {
                    id: 1,
                    classnames: 'heading text-white',
                    title: 'LEADING LANDSCAPERS'
                }
            ],
            choose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'WHY CHOOSE US?'
                }
            ],
            headingproject: [
                {
                    id: 1,
                    classnames: 'heading text-white',
                    title: 'FEATURED PROJECTS',
                    classtext: 'sub-heading'
                }
            ]
        };
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-3 topbar-style-3 site-header-absolute menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {this.state.headers.map((data, idx) => (
                                <Header data={data} key={idx} />
                            ))}
                        </div>
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <div className="rev_slider_wrapper fullwidthbanner-container">
                                                <div
                                                    id="rev-slider2"
                                                    className="rev_slider fullwidthabanner">
                                                    <Slider />
                                                </div>
                                            </div>
                                            <div className="row-services">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={60}
                                                                data-mobile={60}
                                                                data-smobile={60}
                                                            />
                                                            {this.state.ourservice.map((data) => (
                                                                <TitleHeading
                                                                    data={data}
                                                                    key={data.id}
                                                                />
                                                            ))}
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop="27"
                                                                data-mobile="35"
                                                                data-smobile="35"></div>
                                                            <SliderService />
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={45}
                                                                data-mobile={60}
                                                                data-smobile={60}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-iconbox bg-row-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={60}
                                                                data-mobile={60}
                                                                data-smobile={60}
                                                            />
                                                            {this.state.leading.map((data) => (
                                                                <TitleHeading
                                                                    data={data}
                                                                    key={data.id}
                                                                />
                                                            ))}
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={25}
                                                                data-mobile={35}
                                                                data-smobile={35}
                                                            />
                                                            <IconBox />
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div
                                                                        className="themesflat-spacer clearfix"
                                                                        data-desktop={80}
                                                                        data-mobile={60}
                                                                        data-smobile={60}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-whyus">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={61}
                                                                data-mobile={60}
                                                                data-smobile={60}
                                                            />
                                                            <div
                                                                className="themesflat-spacer clearfix"
                                                                data-desktop={29}
                                                                data-mobile={35}
                                                                data-smobile={35}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-project parallax parallax-1 parallax-overlay">
                                                <div className="project-overlay">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div
                                                                    className="themesflat-spacer clearfix"
                                                                    data-desktop={60}
                                                                    data-mobile={60}
                                                                    data-smobile={60}
                                                                />
                                                                {this.state.headingproject.map(
                                                                    (data) => (
                                                                        <TitleHeading
                                                                            data={data}
                                                                            key={data.id}
                                                                        />
                                                                    )
                                                                )}
                                                                <div
                                                                    className="themesflat-spacer clearfix"
                                                                    data-desktop={30}
                                                                    data-mobile={35}
                                                                    data-smobile={35}
                                                                />

                                                                <Project />
                                                                <div
                                                                    className="themesflat-spacer clearfix"
                                                                    data-desktop={41}
                                                                    data-mobile={35}
                                                                    data-smobile={35}
                                                                />
                                                                <div className="swipe-message">
                                                                    Swipe to see more images or
                                                                </div>

                                                                <div className="elm-button text-center">
                                                                    <a
                                                                        href="gallery"
                                                                        className="themesflat-button bg-accent big">
                                                                        <span>
                                                                            See All Projects
                                                                        </span>
                                                                    </a>
                                                                </div>

                                                                <div
                                                                    className="themesflat-spacer clearfix"
                                                                    data-desktop={73}
                                                                    data-mobile={60}
                                                                    data-smobile={60}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {this.state.headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Home02;
