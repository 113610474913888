const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/'
    },
    {
        id: 3,
        name: 'Services',
        links: '/services'
    },
    {
        id: 4,
        name: 'Gallery',
        links: '/gallery'
    },

    {
        id: 6,
        name: 'Contact',
        links: '/contacts'
    }
];

export default menus;
